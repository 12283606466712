;(function () {
  'use strict'

  var MAP_ELEMENT_ID = 'locations-map'

  angular
    .module('ottomatikStoreManager.statistics')
    .controller('StatisticsLocationsController', StatisticsLocationsController)
    .service('GoogleMapsService', GoogleMapsService)

  function StatisticsLocationsController($filter, $scope, GoogleMapsService, LocalStorageService, StatisticService) {
    var $ctrl = this
    var deregistrators = []

    deregistrators.push(
      $scope.$watch('filter.customer', function (customer) {
        $scope.systemId = []
      })
    )

    $scope.resetDisplay = true

    $scope.load = function (event) {
      if ($scope.loading) {
        return
      }

      var filter = angular.copy($scope.filter)
      var params = {
        customerId: filter.customerId,
        storeId: filter.storeId,
        systemId: $scope.systemId,
        dateFrom: $filter('date')(filter.dates.from),
        dateTo: $filter('date')(filter.dates.to),
        timeRange: filter.timeRange.value || undefined,
        items: $scope.items || undefined,
        total: $scope.total || undefined,
        onlyNewCustomers: $scope.onlyNewCustomers || undefined,
      }

      $scope.loading = true
      StatisticService.getLocations(params)
        .then(function (response) {
          $scope.stores = filter.store.length ? filter.store : [filter.store]
          $scope.deliveryAreas.show = $scope.stores.some((store) => store.deliveryAreaFilepath)

          if (!response.locations.length) {
            $scope.clear()
            $scope.noData = true
            return Promise.reject()
          }
          $scope.noData = false
          return initMap(response)
        })
        .then(function (response) {
          $scope.storeTitle = filter.store.length === 1 ? filter.store[0].name : filter.store.length + ' Stores'
          $scope.locationsCount = response.locations.length
          $scope.ordersCount = response.locations.reduce((cnt, loc) => cnt + loc.weight, 0)
          $scope.dateRange = [filter.dates.from, filter.dates.to]
        })
        .finally(function () {
          $scope.loading = false
        })
    }

    $scope.clear = function (event) {
      delete $scope.storeTitle
      delete $ctrl.map
      delete $ctrl.bounds
      delete $ctrl.heatmap
      delete $ctrl.markers
    }

    $scope.fitBounds = function (event) {
      if ($ctrl.map && $ctrl.bounds) {
        $ctrl.map.fitBounds($ctrl.bounds)
      }
    }

    var RADIUS_LOCAL_STORAGE_KEY = 'locations_radius_value'
    var RADIUS_STEP = 500
    $scope.radius = {
      active: false,
      value: Math.round(LocalStorageService.get(RADIUS_LOCAL_STORAGE_KEY, 2500) / RADIUS_STEP) * RADIUS_STEP,
      circles: {},
      toggle: function (event) {
        this.active = !this.active
        this.update()
      },
      dec: function () {
        if (this.value > RADIUS_STEP) {
          this.value -= RADIUS_STEP
          if (this.active) {
            this.update()
          }
          LocalStorageService.set(RADIUS_LOCAL_STORAGE_KEY, this.value)
        }
      },
      inc: function () {
        this.value += RADIUS_STEP
        if (this.active) {
          this.update()
        }
        LocalStorageService.set(RADIUS_LOCAL_STORAGE_KEY, this.value)
      },
      update: function () {
        if (!$ctrl.map || !$ctrl.markers) {
          return
        }

        var activeCircleKeys = []

        if (this.active) {
          $ctrl.markers.forEach((marker) => {
            var key = this.value + ';' + marker.getTitle()
            if (!Object.keys(this.circles).includes(key)) {
              this.circles[key] = createCircle(marker.getPosition(), this.value)
            }
            activeCircleKeys.push(key)
          })
        }

        var activeCircles = []

        angular.forEach(this.circles, (circle, key) => {
          if (activeCircleKeys.includes(key)) {
            activeCircles.push(circle)
            circle.setMap($ctrl.map)
          } else {
            circle.setMap(null)
          }
        })

        if (this.active) {
          this.countHeatmapPointsInside(activeCircles, this.value).then((data) => {
            if (!this.infobox) {
              var div = document.createElement('div')
              this.infobox = document.createElement('div')
              this.infobox.classList.add('infobox')
              div.appendChild(this.infobox)
              $ctrl.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(div)
            }

            var html = '<b>Radius-Filter: ' + $filter('number')(this.value / 1000, 1) + ' km</b>'
            html +=
              '<hr>Adressen: ' +
              $filter('number')(data.absolute) +
              ' / ' +
              $filter('number')($scope.locationsCount) +
              ' (' +
              $filter('percent')(data.absolute / $scope.locationsCount, 1) +
              ')'
            html +=
              '<br>Bestellungen: ' +
              $filter('number')(data.weighted) +
              ' / ' +
              $filter('number')($scope.ordersCount) +
              ' (' +
              $filter('percent')(data.weighted / $scope.ordersCount, 1) +
              ')'

            this.infobox.style.display = ''
            this.infobox.innerHTML = html
          })
        } else if (this.infobox) {
          this.infobox.style.display = 'none'
        }
      },
      countHeatmapPointsInside: function (circles, radius) {
        if (!$ctrl.heatmap) {
          return Promise.reject()
        }

        return GoogleMapsService.importLibrary('geometry').then(() => {
          var countAbsolute = 0
          var countWeighted = 0

          $ctrl.heatmap.getData().forEach((weightedLocation) => {
            circles.some((circle) => {
              var from = circle.getCenter()
              var to = weightedLocation.location
              if (google.maps.geometry.spherical.computeDistanceBetween(from, to) <= radius) {
                countAbsolute += 1
                countWeighted += weightedLocation.weight
                return true
              }
              return false
            })
          })

          return {
            absolute: countAbsolute,
            weighted: countWeighted,
          }
        })
      },
    }

    $scope.deliveryAreas = {
      show: false,
      active: false,
      layers: {},
      toggle: function (event) {
        this.active = !this.active
        this.update()
      },
      update: function () {
        if (!$ctrl.map) {
          return
        }

        var activeStoreIds = []
        if (this.active) {
          activeStoreIds = $scope.stores.map((store) => {
            var key = store.storeId.toString()
            if (!Object.keys(this.layers).includes(key)) {
              this.layers[key] = createPolygonLayer(store)
            }
            return key
          })
        }

        angular.forEach(this.layers, (layer, storeId) => {
          if (!layer) {
            return
          }
          if (activeStoreIds.includes(storeId)) {
            layer.setMap($ctrl.map)
          } else {
            layer.setMap(null)
          }
        })
      },
    }

    $scope.$on('$destroy', function () {
      deregistrators.forEach(function (deregistrator) {
        deregistrator()
      })
    })

    function initMap(data) {
      return GoogleMapsService.load(data.apiKey)
        .then(function () {
          return GoogleMapsService.importLibrary('visualization')
        })
        .then(function () {
          var isNewMap = !$ctrl.map
          var heatmapPoints = []
          var sumWeight = 0
          $ctrl.bounds = new google.maps.LatLngBounds()

          data.locations.forEach(function (location) {
            var LatLng = new google.maps.LatLng(location.latitude, location.longitude)
            heatmapPoints.push({ location: LatLng, weight: location.weight })
            sumWeight += location.weight
            $ctrl.bounds.extend(LatLng)
          })

          // MAP
          if (!$ctrl.map) {
            $ctrl.map = new google.maps.Map(document.getElementById(MAP_ELEMENT_ID), {
              rotateControl: false,
              streetViewControl: false,
            })
          }
          if (isNewMap || $scope.resetDisplay) {
            $ctrl.map.fitBounds($ctrl.bounds)
          }

          // HEATMAP LAYER
          if (!$ctrl.heatmap) {
            $ctrl.heatmap = new google.maps.visualization.HeatmapLayer({
              map: $ctrl.map,
              maxIntensity: sumWeight / data.locations.length, // mean weight
            })
          }
          $ctrl.heatmap.setData(heatmapPoints)

          // MARKERS
          if ($ctrl.markers) {
            $ctrl.markers.forEach(function (marker) {
              marker.setMap(null)
            })
          }
          $ctrl.markers = []
          data.markers.forEach(function (marker) {
            $ctrl.markers.push(createCustomerMarker($ctrl.map, marker))
          })

          // RADIUS
          $scope.radius.update()

          // DELIVERY AREAS
          $scope.deliveryAreas.update()

          return data
        })
    }

    function createCustomerMarker(map, data) {
      var icon
      var customer = $scope.filter.customer
      if (customer.options.geocodingMarkerIcon) {
        icon = {
          url: customer.options.geocodingMarkerIcon,
          scaledSize: new google.maps.Size(48, 48),
        }
      }
      return new google.maps.Marker({
        position: new google.maps.LatLng(data.latitude, data.longitude),
        title: data.title,
        icon: icon,
        map: map,
      })
    }

    function createCircle(center, radius) {
      return new google.maps.Circle({
        center: center,
        radius: radius,
      })
    }

    function createPolygonLayer(store) {
      if (!store.deliveryAreaFilepath) {
        return null
      }
      return new google.maps.KmlLayer({
        url: window.location.origin + $scope.$root.MEDIA_BASE_URL + store.deliveryAreaFilepath,
        preserveViewport: true,
      })
    }
  }

  function GoogleMapsService($q) {
    var service = this
    var currentApiKey

    service.load = load
    service.importLibrary = importLibrary

    function load(key) {
      return $q(function (resolve, reject) {
        if (currentApiKey === key) {
          resolve()
          return
        }
        removeGoogleMaps()
        createBootstrapLoader(key)
        currentApiKey = key
        resolve()
      })
    }

    function importLibrary(library) {
      return $q((resolve, reject) => {
        google.maps.importLibrary(library).then(resolve, reject)
      })
    }

    function createBootstrapLoader(key) {
      // ES5 conversion of https://developers.google.com/maps/documentation/javascript/load-maps-js-api
      // prettier-ignore
      (function(g){var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=function u(){return h||(h=new Promise((function(f,n){a=m.createElement("script");e.set("libraries",Array.from(r)+"");for(k in g)e.set(k.replace(/[A-Z]/g,(function(t){return"_"+t[0].toLowerCase()})),g[k]);e.set("callback",c+".maps."+q);a.src="https://maps."+c+"apis.com/maps/api/js?"+e;d[q]=f;a.onerror=function(){return h=n(Error(p+" could not load."))};m.head.append(a)})))};d[l]?void 0:d[l]=function(f){for(var _len=arguments.length,n=new Array(_len>1?_len-1:0),_key=1;_key<_len;_key++){n[_key-1]=arguments[_key]}return r.add(f)&&u().then((function(){return d[l].apply(d,[f].concat(n))}))}})({
        key: key,
        language: 'de',
        region: 'DE',
      })
    }

    function removeGoogleMaps() {
      delete google.maps
      document.querySelectorAll('script[src*=maps\\.googleapis\\.com]').forEach(function (node) {
        node.parentNode.removeChild(node)
      })
    }
  }
})()
